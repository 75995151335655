import classes from './SocialNetworking.module.css'
import linkedin from '../../image/social/linkedin.png'
import github from '../../image/social/github.png'
import hackerrank from '../../image/social/hackerrank.jpeg'
import twitter from '../../image/social/twitter.png'
import global from '../../../globalclass.module.css'

function SocialNetworking(){
    return(
        <div className={classes["mainContainer"] }>
            <div className={classes["socialIconContainer"]}>
                <div className={global["tooltip"]}>
                    <a href={"https://github.com/AtulShandilya"} target={'_blank'}><img className={classes["socialIcon"]} src={github}/></a>
                    <span className={global["tooltiptext"]}>Github</span>
                </div>
                <div className={global["tooltip"]}>
                    <a href={"https://www.linkedin.com/in/atul-kumar-2b6558b0"} target={'_blank'}><img className={classes["socialIcon"]} src={linkedin}/></a>
                    <span className={global["tooltiptext"]}>Linkedin</span>
                </div>
                <div className={global["tooltip"]}>
                    <a href={"https://www.hackerrank.com/atul_shandilya"} target={'_blank'}><img className={classes["socialIcon"]} src={hackerrank}/></a>
                    <span className={global["tooltiptext"]}>HackerRank</span>
                </div>
                <div className={global["tooltip"]}>
                    <a href={"https://twitter.com/Shandilya_atul"} target={'_blank'}><img className={classes["socialIcon"]} src={twitter}/></a>
                    <span className={global["tooltiptext"]}>Twitter</span>
                </div>
                <div className={classes["socialPanelLine"]}></div>

            </div>

        </div>
    )
}
export default SocialNetworking