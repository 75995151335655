import classes from './UnderDevelopment.module.css'

function UnderDevelopment(){

    return(
        <main className={classes["main"]}>
            <section className={classes["comingSoon"]}>
                <h1>
                    Coming Soon!
                </h1>
            </section>
            <section className={classes["desc"]}>
                <h4>
                    I am currently working on this feature and will launch soon!
                </h4>
            </section>
        </main>
    )
}
export default UnderDevelopment