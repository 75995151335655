import classes from './PortfolioProjects.module.css'
import global from "../../../globalclass.module.css";
import restaurant from '../../image/portfolio/Restaurant1.png'
import musicStudioPic from '../../image/portfolio/MusicStudio1.png'
import globalVar from "../../../GlobalVar";

function PortfolioProjects(){
    // let urlRestaurant='http://localhost:3000/portfolio/restaurant'
    return(
        <section id="portfolio" className={classes["mainContainer"]}>
            <div className={[global.h2size, classes['header']].join(" ")}><span>03.</span> Things I've Build.
                <div className={global['horizontalLine']}></div>
            </div>
            {/* Project Restaurant*/}
            <div className={classes["portfolioContainer"]}>
                <div className={classes["portfolioPic"]}>
                    <a href={globalVar().restaurantUrl} target='_blank'><img src={restaurant} className={classes["image"]}/></a>
                </div>
                <div className={classes["portfolioDetails"]}>
                    <div className={classes['featured']}>Featured Project</div>
                    <div className={classes["appName"]}>Restaurant App</div>
                    <div className={classes["projectDescription"]}>
                        A restaurant digital menu with direct order facility. The Admin login has features to <span>add</span>,
                        <span> delete</span> and <span>hide</span> dishes from the restaurant menu.
                    </div>
                    <div className={classes["technology"]}>
                        <p>React</p>
                        <p>Npn</p>
                        <p>Node</p>
                        <p>Express</p>
                        <p>AWS-ec2</p>
                        <p>AWS-LoadBalancer</p>
                        {/*React  Npm  Node  Express   AWS-EC2   AWS-LoadBalancer*/}
                    </div>
                </div>
            </div>
            {/* Project Music App*/}
            <div className={classes["portfolioContainer"]}>
                <div className={classes["portfolioDetails1"]}>
                    <div className={classes['featured']}>Featured Project</div>
                    <div className={classes["appName"]}>Music Studio</div>
                    <div className={classes["projectDescription1"]}>
                        A Music player App with online streaming where songs grouped into <span>Artist</span>,
                        <span> Album</span> and <span>Type</span> .
                    </div>
                    <div className={classes["technology"]}>
                        <p>Html</p>
                        <p>CSS</p>
                        <p>Javascript</p>
                        <p>Node</p>
                        <p>Express</p>
                    </div>
                </div>
                <div className={classes["portfolioPic"]}>
                    <a  ><img src={musicStudioPic} className={classes["image"]}/></a>
                </div>

            </div>
        </section>
    )
}
export default PortfolioProjects