import classes from './AppBody.module.css'
import '../../globalvar.css'
import Games from './panel/Games'
import MainBody from './MainBody'
import SocialNetworking from "./panel/SocialNetworking";
function AppBody(){

    setTimeout(()=>{
        document.getElementById("AppBody").style.display="flex";
    },500)

    return(
        <div id="AppBody" className={classes["BodyContainer"]}>
            <SocialNetworking/>
            <MainBody />
            <Games />
        </div>
    )
}
export default AppBody;