import classes from "./AppHeader.module.css";
import '../../globalvar.css'
import React from "react";
import globalVar from "../../GlobalVar";
function AppHeader(){
    return(
        <div className={classes["header"]}>
            <div className={classes["logo"]}>
                <svg id="svg"  fill="#282c34" viewBox="0, 0, 200,200" >
                    <g id="svg-id"   transform="translate(10.000000,-5.000000) scale(0.450000,0.450000)">
                        <path id="path1" className={classes["outerlogo"]}  stroke="#61dafb" strokeWidth="8"   d="M78.981 130.692 C 57.134 168.547,39.259 199.737,39.259 200.004 C 39.259 200.270,57.134 231.459,78.981 269.312 L 118.704 338.136 150.463 338.142 L 182.222 338.148 182.222 326.299 L 182.222 314.450 157.318 314.354 L 132.414 314.259 129.183 308.704 C 127.407 305.648,124.996 301.481,123.826 299.444 C 122.656 297.407,109.316 274.284,94.182 248.059 C 79.049 221.834,66.667 200.208,66.667 200.000 C 66.667 199.792,79.049 178.166,94.182 151.941 C 109.316 125.716,122.656 102.593,123.826 100.556 C 124.996 98.519,127.407 94.352,129.183 91.296 L 132.414 85.741 198.522 85.651 L 264.630 85.562 267.445 90.466 C 273.110 100.337,285.925 122.557,307.930 160.664 C 321.316 183.846,330.381 199.918,330.282 200.294 C 330.148 200.807,298.089 256.538,271.863 301.852 C 268.856 307.046,265.999 312.003,265.513 312.868 L 264.630 314.439 239.722 314.442 L 214.815 314.444 214.815 326.296 L 214.815 338.148 246.588 338.148 L 278.360 338.148 318.242 269.074 L 358.123 200.000 318.242 130.926 L 278.360 61.852 198.532 61.858 L 118.704 61.864 78.981 130.692"   />
                        <path id="path2" stroke="#61dafb" strokeWidth="8" d="M201.546 107.441 C 196.466 110.046,191.158 114.804,189.295 118.421 C 188.270 120.411,188.202 122.575,189.050 126.249 C 190.445 132.297,243.897 287.659,245.292 289.723 C 250.854 297.951,269.397 285.286,267.357 274.652 C 266.874 272.137,211.539 110.810,210.379 108.536 C 208.769 105.380,206.190 105.060,201.546 107.441 M181.788 144.828 C 180.391 145.643,179.008 149.469,165.903 188.768 C 150.802 234.051,151.564 231.520,152.488 233.333 C 153.618 235.553,153.054 235.512,182.892 235.535 C 212.589 235.558,212.885 235.539,214.135 233.558 C 215.764 230.978,213.448 220.172,210.522 216.694 C 209.009 214.897,207.818 214.725,195.558 214.540 C 178.714 214.286,179.533 216.296,186.977 193.504 C 194.097 171.705,194.185 171.166,192.054 162.366 C 188.765 148.784,185.263 142.801,181.788 144.828 M150.947 244.256 C 149.064 244.927,147.751 245.918,146.963 247.264 C 146.183 248.594,132.965 286.365,132.694 288.037 C 131.509 295.340,145.667 293.735,154.002 285.620 C 158.978 280.776,160.067 278.584,165.069 263.338 C 170.626 246.402,170.718 245.179,166.512 244.120 C 164.078 243.507,152.772 243.606,150.947 244.256 " />
                    </g>
                </svg>
                {/*<svg id="svg" className={classes["innerlogo"]} fill="#282c34"  viewBox="0, 0, 200,200" >*/}
                {/*    <g id="svg-id"   transform="translate(10.000000,-5.000000) scale(0.450000,0.450000)">*/}
                {/*        /!*<path id="path1" stroke="#61dafb" stroke-width="8"   d="M78.981 130.692 C 57.134 168.547,39.259 199.737,39.259 200.004 C 39.259 200.270,57.134 231.459,78.981 269.312 L 118.704 338.136 150.463 338.142 L 182.222 338.148 182.222 326.299 L 182.222 314.450 157.318 314.354 L 132.414 314.259 129.183 308.704 C 127.407 305.648,124.996 301.481,123.826 299.444 C 122.656 297.407,109.316 274.284,94.182 248.059 C 79.049 221.834,66.667 200.208,66.667 200.000 C 66.667 199.792,79.049 178.166,94.182 151.941 C 109.316 125.716,122.656 102.593,123.826 100.556 C 124.996 98.519,127.407 94.352,129.183 91.296 L 132.414 85.741 198.522 85.651 L 264.630 85.562 267.445 90.466 C 273.110 100.337,285.925 122.557,307.930 160.664 C 321.316 183.846,330.381 199.918,330.282 200.294 C 330.148 200.807,298.089 256.538,271.863 301.852 C 268.856 307.046,265.999 312.003,265.513 312.868 L 264.630 314.439 239.722 314.442 L 214.815 314.444 214.815 326.296 L 214.815 338.148 246.588 338.148 L 278.360 338.148 318.242 269.074 L 358.123 200.000 318.242 130.926 L 278.360 61.852 198.532 61.858 L 118.704 61.864 78.981 130.692 M201.546 107.441 C 196.466 110.046,191.158 114.804,189.295 118.421 C 188.270 120.411,188.202 122.575,189.050 126.249 C 190.445 132.297,243.897 287.659,245.292 289.723 C 250.854 297.951,269.397 285.286,267.357 274.652 C 266.874 272.137,211.539 110.810,210.379 108.536 C 208.769 105.380,206.190 105.060,201.546 107.441"   />*!/*/}
                {/*        <path id="path2" stroke="#61dafb" stroke-width="8" d="M201.546 107.441 C 196.466 110.046,191.158 114.804,189.295 118.421 C 188.270 120.411,188.202 122.575,189.050 126.249 C 190.445 132.297,243.897 287.659,245.292 289.723 C 250.854 297.951,269.397 285.286,267.357 274.652 C 266.874 272.137,211.539 110.810,210.379 108.536 C 208.769 105.380,206.190 105.060,201.546 107.441 M181.788 144.828 C 180.391 145.643,179.008 149.469,165.903 188.768 C 150.802 234.051,151.564 231.520,152.488 233.333 C 153.618 235.553,153.054 235.512,182.892 235.535 C 212.589 235.558,212.885 235.539,214.135 233.558 C 215.764 230.978,213.448 220.172,210.522 216.694 C 209.009 214.897,207.818 214.725,195.558 214.540 C 178.714 214.286,179.533 216.296,186.977 193.504 C 194.097 171.705,194.185 171.166,192.054 162.366 C 188.765 148.784,185.263 142.801,181.788 144.828 M150.947 244.256 C 149.064 244.927,147.751 245.918,146.963 247.264 C 146.183 248.594,132.965 286.365,132.694 288.037 C 131.509 295.340,145.667 293.735,154.002 285.620 C 158.978 280.776,160.067 278.584,165.069 263.338 C 170.626 246.402,170.718 245.179,166.512 244.120 C 164.078 243.507,152.772 243.606,150.947 244.256 " />*/}
                {/*    </g>*/}
                {/*</svg>*/}

            </div>
            <div className={classes["menu"]}>
                <a href={'#about'}><bdi>01.</bdi> About</a>
                <a href={'#experience'}><bdi>02.</bdi> Experience</a>
                <a href={'#portfolio'}><bdi>03.</bdi> Work</a>
                <a href={'#contacts'}><bdi>04.</bdi> Contact</a>
                <a href={globalVar().blogUrl} target={'_blank'}><bdi>Blogs</bdi></a>
            </div>
        </div>
    )
}
export default AppHeader;