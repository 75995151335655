import React,{useContext} from 'react'
import RestaurantContext from '../../../../Context/DishContext.js'
import classes from './BodyHeader.module.css'
const BodyHeader=(props)=>{
    const ctx=useContext(RestaurantContext);
    const selectType=(event)=>{  // to pass selected value in context
        ctx.controlShowSpecificDishType(event.target.innerHTML);
    }
    let dishTypeArray=['All',...Object.keys(ctx.dishList)];
    // console.log("dishArrayList",dishTypeArray);
    // console.log("ctx.dtype",ctx.control.showSpecificDishType)
    return (
        <div  className={classes['dish-type-container']}>
            {
                dishTypeArray.map(dType=>
                    ctx.control.showSpecificDishType==dType?
                        <div onClick={selectType} className={classes['dish-type-selected']}>{dType}</div>
                        : <div onClick={selectType} className={classes['dish-type']}>{dType}</div>

                )
            }
        </div>
    )
    // return (
    //     // <div onClick={ctx.controlHideAll} className={classes['dish-type-container']}>
    //     <div  className={classes['dish-type-container']}>
    //         {
    //             Object.keys(ctx.dishList).map(dType=>
    //                 <div onClick={selectType} className={classes['dish-type']}>{dType}</div>
    //             )
    //         }
    //     </div>
    // )
}

export default BodyHeader