import React,{useContext} from 'react'
import RestaurantContext from '../../../Context/DishContext.js'
import BodyHeader from "./BodyHeader/BodyHeader";
import AddDish from "../AddDish/AddDish";
import classes from "./MainBody.module.css"
import MainBodyMenuDetails from "./DishDetails/MainBodyMenuDetails"
import RestaurantCart from "../Cart/RestaurantCart";
import RestaurantLogin from "../../Login/Login/RestaurantLogin";
const MainBody=(props)=>{
    const ctx=useContext(RestaurantContext);
    // console.log("new:",ctx);
    // console.log("UserCart",ctx.userInfo.Cart);

    return (
        <React.Fragment>
            <div  className={classes["mainbody-header-container"]}>
                <BodyHeader/>

                {ctx.control.showAddItem || ctx.control.showCart || ctx.control.showLogin ?
                    <div className={classes["add-menu-container"]}>
                        { ctx.control.showAddItem?<AddDish/>:""}
                        { ctx.control.showCart?<RestaurantCart/>:""}
                        {ctx.control.showLogin?<RestaurantLogin/>:""}
                    </div> : <div/>
                }
            </div>
            <div className={classes["mainbody-menu-container"]}>
                {
                    Object.keys(ctx.dishList ).map(DList=>
                        ctx.control.showSpecificDishType==DList || ctx.control.showSpecificDishType=='All'||ctx.control.showSpecificDishType==undefined?
                        <div>
                            {
                                ctx.dishList[DList]['dishCount']>0 || (ctx.userInfo.userName).toUpperCase()=='ADMIN' ?
                                <div className={classes.menuDishType}>
                                    {DList}
                                </div>
                                :""
                            }

                            {
                                Object.keys(ctx.dishList[DList]['dishTypeList']).map(DishName=>
                                        //
                                    <div>
                                        {
                                            (ctx.userInfo.userName).toUpperCase()=='ADMIN' || ctx.dishList[DList]['dishTypeList'][DishName]["showDish"]?
                                                <MainBodyMenuDetails DishName={DishName} DishType={DList}/>
                                                :""
                                        }
                                        {/*<MainBodyMenuDetails DishName={DishName} DishType={DList}/>*/}
                                    </div>
                                )
                            }
                        </div>:""
                    )
                }

            </div>
        </React.Fragment>
    )

}

export default MainBody