import './imatul.css'
import '../globalvar.css'
import InitialLogo from "./logo/InitialLogo";
import AppHeader from "./header/AppHeader";
import AppBody from './body/AppBody'
function Imatul() {
    return (
        <div className="imatul">
            <header className="imatulheader">
                <InitialLogo />
                <AppHeader/>
                <AppBody/>
            </header>
        </div>
    )
}

export default Imatul;
