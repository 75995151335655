import classes from './MainBody.module.css'
import '../../globalvar.css'
import Intro from './sections/Intro'
import About from "./sections/About";
import Contact from "./sections/Contact";
import CorporateExperience from './sections/CorporateExperience.js';
import PortfolioProjects from "./sections/PortfolioProjects";

function MainBody(){
    return(
        <section className={classes["MainBodyContainer"]}>
            <Intro/>
            <About/>
            <CorporateExperience/>
            <PortfolioProjects/>
            <Contact/>

        </section>
    )

}
export default MainBody