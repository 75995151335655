import React,{useState} from 'react'
import classes from './contact.module.css'
import global from "../../../globalclass.module.css";
import {contactMe} from '../../API/APIFetch'


function Contact(){
    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [message,setMessage]=useState("");
    const nameHandler=(event)=>{setName(event.target.value)}
    const emailHandler=(event)=>{setEmail(event.target.value)}
    const messageHandler=(event)=>{setMessage(event.target.value)}
    const contactService=(event)=>{
        event.preventDefault();
        contactMe(name,email,message);
        setName("");
        setEmail("");
        setMessage("");
    }

    return(
        <section id="contacts" className={classes["container"]}>
            <div className={[global.h3size, classes['contact1']].join(" ")}><span>04. What's Next?</span>
                <div className={global['horizontalLine']}></div>
            </div>
            <div className={[global.h2size, classes['contact']].join(" ")}> Get In Touch!
            </div>
            <div>
                <form onSubmit={contactService} className={classes["contact-form"]}>
                    <div className={classes["input-field"]}>
                        <input onChange={nameHandler} type="text" name="name" required placeholder="Name" value={name}/>
                        <input onChange={emailHandler} type="text" name="email" required placeholder="Email" value={email}/>
                        <textarea onChange={messageHandler} name="input-message" rows="4" cols="40" placeholder="Message" value={message}/>
                    </div>
                    <button className="submit">SEND</button>
                </form>
            </div>
        </section>
    )
}
export default Contact