import React from 'react';
import {Route, Switch} from "react-router-dom";
import './globalvar.css'
import  './App.css';
import ImAtul from "./components/Imatul";
import EatOnline from './components/portfolio/EatOnline/EatOnline'
import UnderDevelopment from "./components/portfolio/NotFound/UnderDevelopment";

function App() {
  return (
      <main>
          <Switch>
              <Route path="/" exact>
                  <ImAtul/>
              </Route>
              <Route path="/portfolio/restaurant" exact>
                  <EatOnline />
              </Route>
              <Route path="/portfolio/UnderDev" exact>
                  <UnderDevelopment />
              </Route>
          </Switch>

      </main>
  )
}

export default App;
