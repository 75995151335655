import classes from './About.module.css'
import global from '../../../globalclass.module.css'
import mypic from '../../image/mypic.jpg'

function About(){
    return(
        <section id="about">
            <div className={[global.h2size, classes['aboutme']].join(" ")}><span>01.</span> About Me
                <div className={global['horizontalLine']}></div>
            </div>
            <div className={classes.About}>
                <div className={classes.AboutDesc}>
                    <p className={[global.h3size,classes['h3-about'],global.colorgrey].join(" ")}>
                        Hello! My name is Atul and I enjoy creating things that live on the internet.
                        My interest in web development started back in 2019 when I faced problem of
                        not being able to keep my application portable and accessible at all time and places.
                        I started with basic web app in vanilla js and slowly moved to mid-large app using
                        React, redux and other libraries. In my web journey I have invested plenty of time building
                        static web pages perfecting my web design, it has helped me learn a ton about HTML and CSS!
                    </p>
                    <p className={[global.h3size,classes['h3-about'],global.colorgrey].join(" ")}>
                        I have total of 11 years of IT experience and have majorly involved in backend
                        application development for large corporate. I have experience in variety of application,
                        ranging from high throughput with lightning fast response time, to 24/7 application with 99.9%
                        availability. I also have domain exposure in Post-trading, Debit card and Corporate Credit
                        businesses.
                    </p>
                    <p className={[global.h3size,classes['h3-about'],global.colorgrey].join(" ")}>
                        My main focus these days is building webapp with integrated ML/AI API which solves human
                        problems, reduce effort to give best digital experience. I have also hosted plenty of web
                        app on cloud and is working towards harnessing maximum benefit these platforms can provide.
                    </p>
                </div>
                <div className={classes.AboutPic}>
                        <img className={classes["mypic"]} src={mypic}/>
                </div>


            </div>
        </section>
    )
}

export default About;