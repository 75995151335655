import React from 'react'
import globalVar from "../../GlobalVar";

//const ip='http://15.206.151.139:'
// const ip='http://localhost:'
const ip=globalVar().apiURL;
const port=globalVar().apiPort;
const dName='Contact'
//Email Service
export const contactMe= async (name,email,message)=>{
    const readUrl=ip+port+'/EmailService?operation=email&dir='+dName+
        '&name='+name+'&email='+email+'&message='+message
    //console.log("login-url:",readUrl)
    let returnCode=null
    await fetch(readUrl).then(response=>response.json()).then(data=>{
        //console.log("dataaa:",data)
        returnCode=data.returnCode;
        //console.log("in fileApi in fetch, returnCode:",returnCode)
        if(data.returnCode==0){
            alert("Thanks for Contacting!, Will get back soon")
        }else {
            alert(data.returnObj)
        }
    }).catch((errorMsg)=>{ return 3})
    console.log("in fileApi after fetch, returnCode:",returnCode)
    // let promise=new Promise((resolve, reject)=>{
    //     if(returnCode==0) resolve("0")
    //     else reject("1")
    //
    // })
    // return promise
    return returnCode
}