import classes from './CorporateExperience.module.css'
import global from "../../../globalclass.module.css";

function CorporateExperience(){
    setTimeout(()=>{
        document.getElementById("defaultFocus").style.display='block';

    },1000)
    return(
        <section id="experience" className={classes["corporateExperience"]}>
            <div className={[global.h2size, classes['header']].join(" ")}><span>02.</span> Corporate Experience
                <div className={global['horizontalLine']}></div>
            </div>

            <div className={classes['menu']}>
                <div  tabIndex="0" className={classes["button"]} >SIX SIS </div>
                <div id="defaultFocus" className={classes["content"]}>
                    <h5>Module Lead<span> @ SIX SIS</span></h5>
                    <h6>2013 - 2017</h6>
                    <ul>
                        <li>
                            Worked closely with architect and business team to develop, document, integrate
                            Falcon real-time fraud screening and profiling application with debit card application.
                        </li>
                    </ul>

                </div>
                <div  tabIndex="0" className={classes["button"]}>RBS</div>
                <div id="content" className={classes["content"]}>
                    <h5>Tech Lead<span> @ Rbs</span></h5>
                    <h6>2017 - 2019</h6>
                    <ul>
                        <li>
                            Worked closely with architect and business team to develop, document, integrate
                            Falcon real-time fraud screening and profiling application with debit card application.
                        </li>
                    </ul>
                </div>
                <div tabIndex="0" className={classes["button"]}>DNB BANK</div>
                <div id="content"  className={classes["content"]}>
                    <h5>Tech Lead<span> @ Dnb</span></h5>
                    <h6>2019 - 2021</h6>
                    <ul>
                        <li>
                            Designed and developed integration of new in-house scoring
                            engine with personal banking Application.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default CorporateExperience;