import classes from './Games.module.css'
import dice from '../../image/game/dice.png'
import puzzle from  '../../image/game/puzzle.png'
import mushroom from '../../image/game/mushroom.png'
import rubik from '../../image/game/rubik.png'
import globalVar from "../../../GlobalVar";
function Games(){
    return(
        <div className={classes['gamesSection']}>
            <div className={classes['rotate']}>
                <div className={classes["game-text"]}>
                    Games
                </div>
                <div><a href={globalVar().game1Url} target={'_blank'}><img className={classes["gameIcon"]} src={dice}/></a></div>
                <div><a href={globalVar().game2Url} target={'_blank'}><img className={classes["gameIcon"]} src={puzzle}/></a></div>
                <div><a href={globalVar().game3Url} target={'_blank'}><img className={classes["gameIcon"]} src={mushroom}/></a></div>
                <div><a href={globalVar().game4Url} target={'_blank'}><img className={classes["gameIcon"]} src={rubik}/></a></div>

                <div className={classes["line"]}/>
            </div>
        </div>
    )
}
export default Games
