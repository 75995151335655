import classes from './intro.module.css'
import global from '../../../globalclass.module.css'
import globalVar from "../../../GlobalVar";

function Intro(){
    return(
        <section className={classes.intro}>
            <p className={[global.h3size,global.highlighted,classes['h4-intro']].join(" ")}>Hi,  my  name  is</p>
            <p className={[global.h1size,classes['h1-name'],global.colorpearl].join(" ")}>Atul Kumar.</p>
            <p className={[global.h1size,classes['h1-intro1'],global.colorgrey].join(" ")}>I build things for web.</p>
            <p className={[global.h3size,classes['h3-intro1'],global.colorgrey].join(" ")}>I'm a software engineer specializing in building beautiful application for a
                wonderful human experience. Currently I am focused on building accessible,
                human centric product at <span>TCS</span>.
            </p>
            <div className={classes['checkMyBlog']}>
               <a  href={globalVar().blogUrl} target={"_blank"}> Check Out My Blogs!</a>
            </div>
        </section>
    )
}
export default Intro